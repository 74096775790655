<template>

	<div class="explorationDetail">
		<rxNavBar title="实勘"></rxNavBar>
		<div class="leftTitle"><span>实勘信息</span></div>
		<common-empty v-if="isShow"></common-empty>
		<div class="houseDisplayBlock" v-for="(explorationInfo,i) in explorationList" :key="i">
			<div class="header" v-if="explorationInfo.addTime!=null"><span class="house_Addtime">{{explorationInfo.addTime}}</span><span
				 class="house_AddStaff">{{explorationInfo.staffName}}</span></div>
			<div class="middle" v-if="explorationInfo.picList!=null">
				<div class="swiper_show" ref="wrapperBox" style=" touch-action: none;">
					<div class="content">
						<div class="imgWrap" v-for="(pic,j) in explorationInfo.picList" :key="j" @click="previewImg(i,j)">
							
							<van-image width="95" height="75" radius="10" fit="contain" style="background-color: #e1eaec;" :src="pic.path" >
							<span class="num2">
								{{pic.dictionaryTitle}}
							</span>
							</van-image>
						</div>
						<div class="num">
							{{explorationInfo.picList==null? '0': explorationInfo.picList.length}}图
						</div>
					</div>
				</div>
			</div>
			<div class="detailText">
				<p class="descText">{{explorationInfo.explorationDescription}}</p>
			</div>
		</div>
		<div class="addPlaceholder"></div>
		<div class="addButtonDiv">
			<button class="addButton" @click="addPracticalExplorationClick">+ 添加实勘</button>
		</div>
		<meta name="viewport" content="initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, width=device-width,viewport-fit=cover">
	</div>
</template>

<script>
	import {
		NavBar,
		Button,
		Image as VanImage,
		ImagePreview,
	} from 'vant';
	import BScroll from "better-scroll";
	import {
		queryExploration,
	} from "../../../getData/getData";
	import {
		globaluserId,
		responseUtil
	} from "../../../libs/rongxunUtil";
	import Cookies from 'js-cookie';
	import rxNavBar from '../../../components/rongxun/rx-navBar/navBar';
	export default {

		name: 'practicalExplorationInfo',

		components: {
			[NavBar.name]: NavBar, 
			[Button.name]: Button,
			[VanImage.name]: VanImage,
			[ImagePreview.Component.name]: ImagePreview.Component,
			[BScroll.name]: BScroll,
			rxNavBar,
		},
		data() {
			return {
				isShow: false,
				staffName: '',
				id: '',
				explorationList: []
			}
		},
		methods: {
			previewImg(i, j) {
				ImagePreview({
					images: this.explorationList[i].picS,
					startPosition: j,
					onClose() {},
				})
			},
			//添加实勘
			addPracticalExplorationClick() {
				this.$router.push({
					name: 'AddPracticalSurvey',
					query: {
						id: this.id,
						staffName: this.staffName,
						houseType: this.houseType,
						idList: this.idList
					}
				});
			},
			// 页面初始化
			initData: function() {
				var that = this
				let initData = {}
				initData.user_id = globaluserId()
				initData.id = that.id
				queryExploration(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						if (response.data.data.explorationList.length != 0) {
							that.explorationList = response.data.data.explorationList
							console.log(that.explorationList)
							for (var i = 0; i < that.explorationList.length; i++) {
								if (that.explorationList[i].picList) {
									var picS = []
									for (var j = 0; j < that.explorationList[i].picList.length; j++) {
										picS.push(that.explorationList[i].picList[j].path)
									}
									that.explorationList[i].picS = picS
								}
							}
							that.picScroll()
						} else {
							that.isShow = true
						}
					})
				})
			},
			picScroll() {
				this.$nextTick(() => {
					if (!this.scroll) {
						for (let i = 0; i < this.$refs.wrapperBox.length; i++) {
							this.scroll = new BScroll(this.$refs.wrapperBox[i], {
								scrollX: true,
								eventPassthrough: 'vertical'
							})
						}

					}
				})
			}
		},
		created() {
			this.id = this.$route.query.id
			this.houseType = this.$route.query.houseType
			this.idList = this.$route.query.idList
			this.staffName = this.$route.query.staffName
		},
		mounted() {
			this.initData();
		}
	};
</script>

<style lang="less">
	.explorationDetail {
		/*margin-bottom: 80px;*/
		/*padding-bottom: constant(safe-area-inset-bottom);*/
		padding-bottom: env(safe-area-inset-bottom);
	}

	// 图片墙
	.swiper_show {
		width: 9rem;
		position: relative;
		margin: 0px 10px;
		height: 75px;

		.content {
			position: absolute;
			display: flex;
			justify-content: space-around;
			left: 0px;

			.imgWrap {
				margin-right: 15px;
			}
		}

		.num {
			position: absolute;
			left: 4px;
			top: 4px;
			font-size: 10px;
			background-color: rgb(0, 0, 0);
			opacity: .2;
			color: rgb(255, 255, 255);
			padding: 2px 6px;
			display: inline-block;
			border-radius: 10px;
			font-weight: bolder;
			line-height: 10px;
		}
		
	}
	.num2 {
		position: absolute;
		left: 4px;
		bottom: 4px;
		font-size: 10px;
		background-color: rgb(0, 0, 0);
		opacity: .2;
		color: rgb(255, 255, 255);
		padding: 2px 6px;
		display: inline-block;
		border-radius: 10px;
		font-weight: bolder;
		line-height: 10px;
	}

	.addMarginTop {
		//margin-top: 8px;
	}

	.nav-bar {
		background-color: #f8f8f8;
		border: none;
		font-weight: bold;
	}
	.addPlaceholder{
		width: 100%;
		height: 80px;
	}
	.addButtonDiv{
		position: fixed;
		bottom: 0;
		z-index: 3;
		height: calc(70 + env(safe-area-inset-bottom));
		background-color: #ffffff;
		width: 100%;
		padding-bottom: env(safe-area-inset-bottom);
	}
	.addButton {
		background: linear-gradient(to right, #77b2ff 0px, #3898ff 100%) repeat scroll 0% 0%;
		width: 92%;
		margin: 10px auto;
		display: block;
		height: 50px;
		border-radius: 8px;
		font-size: 18px;
		color: white;
		text-align: center;
		border: none;
	}

	.header {
		height: 45px;
		line-height: 45px;
	}

	.house_Addtime {
		font-size: 14px;
		//color: #999999;
		float: left;
		display: inline-block;
		padding-left: 13px;
	}

	.house_AddStaff {
		font-family: 宋体;
		font-weight: bold;
		font-size: 14px;
		float: right;
		display: inline-block;
		padding-right: 13px;
	}

	.middle {
		overflow: hidden;
		height: auto;
	}

	.detailText {
		word-wrap: break-word;
		padding-right: 15px;
	}

	.descText {
		height: auto;
		margin-top: 10px;
		font-size: 14px;
		color: black;
		padding: 5px 10px 20px 10px;
		letter-spacing: 0.6px;
		width: 100%;
	}

	.houseImage {
		width: 103.7px;
		height: 75px;
		margin-left: 8px;
		margin-bottom: 8px;
		float: left;
		display: block;
	}

	.leftTitle {
		overflow: hidden;
		font-size: 13px;
		color: #ea5600;
		font-weight: bold;
		margin-left: 0.3rem;
		margin-bottom: -13px;
		margin-top: 18px;
	}

	.houseDisplayBlock {
		position: relative;
		background-color: white;
		border-radius: 9px;
		width: 9.2rem;
		height: available;
		margin: 19px auto;
	}

	.houseDisplayBlock:nth-of-type(1) {
		margin-top: 60px;
	}

	.imgCount {
		background-color: black;
		opacity: 0.5;
		position: absolute;
		font-size: 9px;
		left: 14px;
		top: 56px;
		z-index: 1;
		color: white;
		width: 30px;
		text-align: center;
		border-radius: 10px;
	}
</style>
